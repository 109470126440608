import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

const ItemContainer = styled.div`
  margin: 30px 0px 30px 0px;
  width: 100%;
  border-radius: 22px;
  box-shadow: 0px 10px 15px -15px #333;

  @media (max-width: 966px) {
    margin: 22px 0px 22px 0px;
  }
`;

const TextContainer = styled.div<{ hasHint: boolean }>`
  padding: 0px 12px 0px 12px;
  background-color: #fff;
  border-radius: ${({ hasHint }) => (hasHint ? "22px 22px 0 0" : "22px")};
  cursor: pointer;
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const ItemText = styled.p<{ isSelected: boolean }>`
  font-weight: ${({ isSelected }) => (isSelected ? "bold" : "normal")};
  display: inline;
  width: 100%;
  text-align: left;
`;
const HintContainer = styled.div`
  padding: 0px 12px 6px 12px;
  background-color: #e1e7e2;
  border-radius: 0 0 22px 22px;
  cursor: pointer;
`;
const HintText = styled.p`
  text-align: left;
  font-size: 13px;
  margin: 0px;
  padding-top: 6px;
`;
const HintActionText = styled.p`
  text-align: center;
  text-decoration: underline;
  font-size: 13px;
  margin: 0px;
  padding-top: 6px;
`;

type Props = { data: Item[]; setData: (data: Item[]) => void };

export function ItemList({ data, setData }: Props) {
  const { t } = useTranslation();
  function _onItemClick(item: Item) {
    let newData = [...data];
    let foundItem: any = newData.find((findItem) => findItem.name === item.name);
    foundItem.isSelected = !foundItem.isSelected;
    setData(newData);
  }
  function _onHintPress(item: Item) {
    let newData = [...data];
    let foundItem: any = newData.find((findItem) => findItem.name === item.name);
    foundItem.hintShown = !foundItem.hintShown;
    setData(newData);
  }

  return (
    <>
      {data.map((item) => (
        <ItemContainer key={item.name}>
          <TextContainer hasHint={!!item.hint} onClick={() => _onItemClick(item)}>
            <FirstRow>
              <ItemText
                isSelected={item.isSelected}
                dangerouslySetInnerHTML={{ __html: t(item.name) }}
              />
              <input
                type='checkbox'
                checked={item.isSelected}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => _onItemClick(item)}
                style={{
                  width: 24,
                  height: 24,
                  marginLeft: "20px",
                }}
              />
            </FirstRow>
          </TextContainer>
          {item.hint && (
            <HintContainer onClick={(e) => _onHintPress(item)}>
              {item.hintShown && <HintText dangerouslySetInnerHTML={{ __html: t(item.hint) }} />}
              <HintActionText>
                {item.hintShown ? (
                  <Trans i18nKey='ItemList.hideExplanation' />
                ) : (
                  <Trans i18nKey='ItemList.showExplanation' />
                )}
              </HintActionText>
            </HintContainer>
          )}
        </ItemContainer>
      ))}
    </>
  );
}
