import React, { useEffect, useState } from "react";
import styled from "styled-components";

import "./App.css";
import { ItemList } from "./components/ItemList";
import { Tables } from "./components/Tables";
import { BLEEDING_DATA_DEFAULT, THROMBOSIS_DATA_DEFAULT } from "./constants/constants";
import { Trans, useTranslation } from "react-i18next";
import i18n from "./i18n";

const MainContainer = styled.div`
  margin: 0px 25% 0px 25%;
  @media (max-width: 966px) {
    margin: 0px 4% 0px 4%;
  }
`;
const SecondaryTitle = styled.h3`
  margin-top: 48px;
  text-align: left;
  font-weight: bold;
`;
const MainExplanation = styled.p`
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;
`;

const InfoText = styled.p`
  font-weight: bold;
  margin-top: 26px;
  text-align: left;
`;

const ResetButton = styled.button`
  margin-top: 26px;
  background-color: #007bff;
  color: #fff;
  border-width: 0px;
  font-size: 16px;
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
`;
const CitationsText = styled.p`
  font-size: 11px;
  color: #333333;
  text-align: left;
`;
const ResultContainer = styled.div`
  background-color: #50a55c;
  padding: 16px;
  margin-top: 48px;
  color: #f3f3f3;
  border-radius: 22px;
  box-shadow: 10px 10px 30px -12px #333;
`;
const LanguageContainer = styled.div`
  @media (max-width: 966px) {
    display: flex;
    justify-content: flex-end;
    padding-top: 4%;
  }
`;
const LanguageSelector = styled.select`
  border-radius: 12px;
  padding: 8px 0px 8px 8px;
  @media (min-width: 966px) {
    position: absolute;
    top: 24px;
    right: 24px;
  }
`;

function App() {
  const [thrombosisData, setThrombosisData] = useState<Item[]>(
    THROMBOSIS_DATA_DEFAULT.map((item) => ({ ...item }))
  );
  const [bleedingData, setBleedingData] = useState<Item[]>(
    BLEEDING_DATA_DEFAULT.map((item) => ({ ...item }))
  );
  const [buttonAnimation, setButtonAnimation] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (buttonAnimation)
      setTimeout(() => {
        setButtonAnimation(false);
      }, 300);
  }, [buttonAnimation]);

  function _getTotalPoints(data: Item[]) {
    return data
      .filter((item) => item.isSelected === true)
      .reduce((a, b) => {
        return a + b.points;
      }, 0);
  }

  /*
    VTE = Thrombosis vysoký pokud >= 3
    Bleeding = vysoký pokud >= 7 nebo Aktivní gastroduodenální vřed

    VTE nízký and/or krvácení High - 
    tak je indikována pouze nefarmakologická profylaxe VTE (časná mobilizace, elastické komprese dolních končetin, hydratace)

    VTE vysoký and krvácení vysoký  - 
    tak je indikována pouze nefarmakologická profylaxe (časná mobilizace, elastické komprese dolních končetin, hydratace)

    VTE nízký and krvácení nízký  - 
    tak není indikována žádná profylaxe VTE
  */

  function _getDiagnose(): Result {
    const bleedingHighForcedItem = bleedingData.find((d) => d.forceTrue && d.isSelected);
    const isThrombosisRiskHigh = _getTotalPoints(thrombosisData) >= 3;
    const isBleedingRiskHigh = _getTotalPoints(bleedingData) >= 7 || !!bleedingHighForcedItem;
    let treatmentText = "";
    let treatmentPoints;
    if (isBleedingRiskHigh) {
      treatmentText = "App.bleedingTreatment.text";
      treatmentPoints = [
        "App.bleedingTreatment.point1",
        "App.bleedingTreatment.point2",
        "App.bleedingTreatment.point3",
      ];
    } else if (isThrombosisRiskHigh) {
      treatmentText = "App.thrombosisTreatment.text";
      treatmentPoints = [
        "App.thrombosisTreatment.point1",
        "App.thrombosisTreatment.point2",
        "App.thrombosisTreatment.point3",
      ];
    } else {
      treatmentText = "App.treatment.none";
    }
    return {
      isBleedingRiskHigh,
      bleedingHighForcedText: bleedingHighForcedItem?.explanationText,
      isThrombosisRiskHigh,
      treatmentText,
      treatmentPoints,
    };
  }

  const diagnose = _getDiagnose();
  return (
    <MainContainer className='App'>
      <LanguageContainer>
        <LanguageSelector
          onChange={(e) => i18n.changeLanguage(e.target.value)}
          defaultValue={i18n.languages[0].slice(0, 2)}>
          <option value='cs'>Čeština</option>
          <option value='en'>English</option>
        </LanguageSelector>
      </LanguageContainer>
      <h2 style={{ marginLeft: "3%", marginRight: "3%" }}>{t("App.title")}</h2>
      <MainExplanation>
        <Trans i18nKey='App.subtitle' />
      </MainExplanation>
      <Trans i18nKey='App.infoText' />
      <div style={{ borderBottomWidth: 1, borderColor: "black", width: "100%" }} />
      <ItemList data={thrombosisData} setData={setThrombosisData} />
      <h2 style={{ marginTop: 48 }}>
        <Trans i18nKey='App.bleedingTitle' />
      </h2>
      <ItemList data={bleedingData} setData={setBleedingData} />

      <div style={{ marginBottom: 64 }}>
        <ResultContainer>
          <SecondaryTitle style={{ marginTop: 0 }}>
            <Trans i18nKey='App.prevention' />
          </SecondaryTitle>
          <p style={{ textAlign: "left", marginTop: 24 }}>
            <Trans
              i18nKey='App.thrombosisRisk'
              values={{
                riskText: diagnose.isThrombosisRiskHigh
                  ? t("App.thrombosisPointsHigh")
                  : t("App.thrombosisPointsLow"),
                points: _getTotalPoints(thrombosisData),
              }}
            />
          </p>
          <p style={{ textAlign: "left" }}>
            <Trans
              i18nKey='App.bleedingRisk'
              values={{
                riskText: diagnose.isBleedingRiskHigh
                  ? t("App.bleedingPointsHigh")
                  : t("App.bleedingPointsLow"),
                points: _getTotalPoints(bleedingData),
                additionalText:
                  diagnose.bleedingHighForcedText && ` - ${diagnose.bleedingHighForcedText}`,
              }}
            />
          </p>
          <p style={{ textAlign: "left", marginBottom: 4, marginTop: 24, fontWeight: "bold" }}>
            {t(diagnose.treatmentText)}
          </p>
          {diagnose.treatmentPoints && (
            <ul style={{ marginTop: 0, marginBottom: 0 }}>
              {diagnose.treatmentPoints.map((point) => (
                <li key={point} style={{ textAlign: "left" }}>
                  {t(point)}
                </li>
              ))}
            </ul>
          )}
        </ResultContainer>
        <ResetButton
          className={buttonAnimation ? "buttonAnimation" : ""}
          onClick={() => {
            setButtonAnimation(true);
            setBleedingData(BLEEDING_DATA_DEFAULT.map((item) => ({ ...item })));
            setThrombosisData(THROMBOSIS_DATA_DEFAULT.map((item) => ({ ...item })));
            window.scroll({ top: 0, left: 0, behavior: "smooth" });
          }}>
          <Trans i18nKey='App.resetButton' />
        </ResetButton>
        <SecondaryTitle>
          <Trans i18nKey='App.explanation' />
        </SecondaryTitle>
        <Tables />
        <InfoText>
          <Trans i18nKey='App.infoText1' />
        </InfoText>
        <InfoText>
          <Trans i18nKey='App.infoText2' />
        </InfoText>
        <CitationsText style={{ marginTop: 32 }}>
          <Trans i18nKey='App.citations' />
        </CitationsText>
        <CitationsText>
          Spyropoulos AC, Anderson FA Jr, FitzGerald G, Decousus H, Pini M, Chong BH, Zotz RB,
          Bergmann JF, Tapson V, Froehlich JB, Monreal M, Merli GJ, Pavanello R, Turpie AGG,
          Nakamura M, Piovella F, Kakkar AK, Spencer FA; IMPROVE Investigators. Predictive and
          associative models to identify hospitalized medical patients at risk for VTE. Chest. 2011
          Sep;140(3):706-714. doi: 10.1378/chest.10-1944
        </CitationsText>
        <CitationsText>
          Rosenberg D, Eichorn A, Alarcon M, McCullagh L, McGinn T, Spyropoulos AC. External
          validation of the risk assessment model of the International Medical Prevention Registry
          on Venous Thromboembolism (IMPROVE) for medical patients in a tertiary health system. J Am
          Heart Assoc. 2014 Nov 17;3(6):e001152. doi: 10.1161/JAHA.114.001152. PMID: 25404191;
          PMCID: PMC4338701.. Epub 2011 Mar 24. PMID: 21436241.
        </CitationsText>
        <CitationsText>
          https://www.nice.org.uk/guidance/ng89/chapter/Recommendations#interventions-for-people-with-psychiatric-illness
        </CitationsText>
        <CitationsText>
          Decousus H, Tapson VF, Bergmann JF, Chong BH, Froehlich JB, Kakkar AK, Merli GJ, Monreal
          M, Nakamura M, Pavanello R, Pini M, Piovella F, Spencer FA, Spyropoulos AC, Turpie AG,
          Zotz RB, Fitzgerald G, Anderson FA; IMPROVE Investigators. Factors at admission associated
          with bleeding risk in medical patients: findings from the IMPROVE investigators. Chest.
          2011 Jan;139(1):69-79. doi: 10.1378/chest.09-3081. Epub 2010 May 7. PMID: 20453069.
        </CitationsText>
        <CitationsText>
          Rosenberg DJ, Press A, Fishbein J, Lesser M, McCullagh L, McGinn T, Spyropoulos AC.
          External validation of the IMPROVE Bleeding Risk Assessment Model in medical patients.
          Thromb Haemost. 2016 Aug 30;116(3):530-6. doi: 10.1160/TH16-01-0003. Epub 2016 Jun 16.
          PMID: 27307054.
        </CitationsText>
        <CitationsText>
          Hostler DC, Marx ES, Moores LK, Petteys SK, Hostler JM, Mitchell JD, Holley PR, Collen JF,
          Foster BE, Holley AB. Validation of the International Medical Prevention Registry on
          Venous Thromboembolism Bleeding Risk Score. Chest. 2016 Feb;149(2):372-9. doi:
          10.1378/chest.14-2842. Epub 2016 Jan 12. PMID: 26867833.
        </CitationsText>
        <CitationsText>
          Skeik N, Westergard E. Recommendations for VTE Prophylaxis in Medically Ill Patients. Ann
          Vasc Dis. 2020 Mar 25;13(1):38-44. doi: 10.3400/avd.ra.19-00115
        </CitationsText>
        <CitationsText>
          This work was supported by the Cooperatio Program, research area CARD
        </CitationsText>
      </div>
    </MainContainer>
  );
}

export default App;
