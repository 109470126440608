export const THROMBOSIS_DATA_DEFAULT = [
  {
    name: "Constants.thrombosis.item1.text",
    points: 3,
    isSelected: false,
    hint: "Constants.thrombosis.item1.hint",
  },
  {
    name: "Constants.thrombosis.item2.text",
    points: 2,
    isSelected: false,
    hint: "Constants.thrombosis.item2.hint",
  },
  {
    name: "Constants.thrombosis.item3.text",
    points: 2,
    isSelected: false,
  },
  {
    name: "Constants.thrombosis.item4.text",
    points: 2,
    isSelected: false,
    hint: "Constants.thrombosis.item4.hint",
  },
  {
    name: "Constants.thrombosis.item5.text",
    points: 1,
    isSelected: false,
    hint: "Constants.thrombosis.item5.hint",
  },
  { name: "Constants.thrombosis.item6.text", points: 1, isSelected: false },
  {
    name: "Constants.thrombosis.item7.text",
    points: 1,
    isSelected: false,
  },
  // { name: "Varixy/žilní insuficience", points: 1, isSelected: false },
  // { name: "Dehydratace", points: 1, isSelected: false },
  // { name: "Věk ≥ 75 let", points: 2, isSelected: true }
];

export const BLEEDING_DATA_DEFAULT = [
  { name: "Constants.bleeding.item1.text", points: 1.5, isSelected: false },
  { name: "Constants.bleeding.item2.text", points: 3.5, isSelected: false },
  {
    name: "Constants.bleeding.item3.text",
    points: 1,
    isSelected: false,
    hint: "Constants.bleeding.item3.hint",
  },
  {
    name: "Constants.bleeding.item4.text",
    points: 2.5,
    isSelected: false,
    hint: "Constants.bleeding.item4.hint",
  },
  {
    name: "Constants.bleeding.item5.text",
    points: 2,
    isSelected: false,
    hint: "Constants.bleeding.item5.hint",
  },
  { name: "Constants.bleeding.item6.text", points: 2, isSelected: false },
  { name: "Constants.bleeding.item7.text", points: 2, isSelected: false },
  { name: "Constants.bleeding.item8.text", points: 2.5, isSelected: false },
  { name: "Constants.bleeding.item9.text", points: 4, isSelected: false },
  {
    name: "Constants.bleeding.item10.text",
    points: 4,
    isSelected: false,
  },
  {
    name: "Constants.bleeding.item11.text",
    points: 4.5,
    isSelected: false,
    hint: "Constants.bleeding.item11.hint",
    explanationText: "Aktivní gastroduodenální vřed je kontraindikací farmakologické profylaxe.",
    forceTrue: true, // If this variable is true, it will automatically trigger high bleeding risk
  },
];
