import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";

const TablesContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const FirstTable = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  margin-right: 32px;
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0px;
  }
`;
const SecondTable = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
  margin-top: 0px;
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 26px;
  }
`;
const TableTh = styled.th`
  border: 1px solid #dddddd;
  padding: 4px;
  font-weight: normal;
  @media (min-width: 768px) {
    width: 128px;
  }
`;
const TableTd = styled.td`
  border: 1px solid #dddddd;
  padding: 4px;
`;
const TableCaption = styled.caption`
  border: 1px solid #dddddd;
  font-weight: bold;
  padding: 4px;
`;

export function Tables() {
  return (
    <TablesContainer>
      <FirstTable>
        <TableCaption>
          <Trans i18nKey='Tables.thrombosisTitle' />
        </TableCaption>
        <tbody>
          <tr>
            <TableTh style={{ flexGrow: 1, flexShrink: 1 }}>
              <Trans i18nKey='Tables.thrombosisPoints' />
            </TableTh>
            <TableTh style={{ flexGrow: 1, flexShrink: 1 }}>
              <Trans i18nKey='Tables.thrombosisRisk' />
            </TableTh>
          </tr>
          <tr>
            <TableTd>0</TableTd>
            <TableTd>0.4%</TableTd>
          </tr>
          <tr>
            <TableTd>1</TableTd>
            <TableTd>0.6%</TableTd>
          </tr>
          <tr>
            <TableTd>2</TableTd>
            <TableTd>1.0%</TableTd>
          </tr>
          <tr>
            <TableTd>3</TableTd>
            <TableTd>1.7%</TableTd>
          </tr>
          <tr>
            <TableTd>4</TableTd>
            <TableTd>2.9%</TableTd>
          </tr>
          <tr>
            <TableTd>5-10</TableTd>
            <TableTd>7.2%</TableTd>
          </tr>
          <tr>
            <TableTd>{`>10`}</TableTd>
            <TableTd>{`>7.2%`}</TableTd>
          </tr>
        </tbody>
      </FirstTable>
      <SecondTable>
        <TableCaption>
          <Trans i18nKey='Tables.bleedingTitle' />
        </TableCaption>
        <tbody>
          <tr>
            <TableTh>
              <Trans i18nKey='Tables.bleedingPoints' />
            </TableTh>
            <TableTh>
              <Trans i18nKey='Tables.bleedingRisk' />
            </TableTh>
          </tr>
          <tr>
            <TableTd>{`<7`}</TableTd>
            <TableTd>
              <Trans i18nKey='Tables.bleedingNoRisk' />
            </TableTd>
          </tr>
          <tr>
            <TableTd>{`≥7`}</TableTd>
            <TableTd>
              <Trans i18nKey='Tables.bleedingHighRisk' />
            </TableTd>
          </tr>
        </tbody>
      </SecondTable>
    </TablesContainer>
  );
}
